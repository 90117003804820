import React from "react"
import { Link } from "gatsby"

const PrevNext = ({prev, next}) => {
	return (
		<div className="post-module-prev-next">
			{
				prev === null ? (
					<p>
						<span>←PREV</span>
						<p className="post-module-prev-next__title">なし</p>
					</p>
				) : (
					<Link to={ prev.fields.slug }>
						<span>←PREV</span>
						<p className="post-module-prev-next__title">{ prev.frontmatter.title }</p>
					</Link>
				)
			}
			{
				next === null ? (
					<p style={{ textAlign: "right" }}>
						<span>NEXT→</span>
						<p className="post-module-prev-next__title">なし</p>
					</p>
				) : (
					<Link to={ next.fields.slug } style={{ textAlign: "right" }}>
						<span>NEXT→</span>
						<p className="post-module-prev-next__title">{ next.frontmatter.title }</p>
					</Link>
				)
			}
		</div>
	);
}

export default PrevNext;